import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogActions } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-custom-dialog',
  templateUrl: './custom-dialog.component.html',
  styleUrls: ['./custom-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class CustomDialogComponent {
  @Input() template: any;
  @Input() actions: any;

  constructor(public dialogRef: MatDialogRef<CustomDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.template = data.template;
    this.actions = data.actions;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onAction(action: any): void {
    if (action && action.callback) {
      const result = action.callback();
      if (result !== false) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}