import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import { ApiBaseService } from 'app/api/api.base.service';
import { SignInResponse } from 'app/modules/auth/sign-in/models/sign-in.response';
import { StorageService } from '../services/storage/storage.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../user/user.types';
import { UserInvite } from 'app/modules/admin/pages/settings/user-settings/models/userInvite';
import { UrlSegment } from '@angular/router';
import { Permissions } from 'app/modules/admin/pages/settings/permission-settings/models/permission';
import { CalendarSettings } from 'app/modules/admin/pages/profile/CalendarView';

@Injectable()
export class AuthService extends ApiBaseService {
    private _authenticated: boolean = false;
    signupForCompanyData: any;
    invitedUserToken: string;

    /**
     * Constructor
     */
    constructor(
        _httpClient: HttpClient,
        private _userService: UserService,
        private _translateService: TranslateService
    ) {
        super(_httpClient, 'api/account');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: { email: string }): Observable<any> {
        return this.post('forgot-password', email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(resetPassword: {
        userId: string;
        token: string;
        password: string;
    }): Observable<any> {
        return this.post('reset-password', resetPassword);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: {
        userName: string;
        password: string;
    }): Observable<SignInResponse> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError('User is already logged in.');
        }

        return this.post('login', credentials).pipe(
            switchMap((response: SignInResponse) => {
                // Store the access token in the local storage & user
                StorageService.setSignInState(response);

                // Set the authenticated flag to true
                this._authenticated = true;

                this.setLanguage(response.user.languageId);

                // Store the user on the user service
                this._userService.user = response.user;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        StorageService.unsetLocalStorage();

        // Set the authenticated flag to false
        this._authenticated = false;

        this._translateService.use('de');

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        username: string;
        firstName: string;
        lastName: string;
        email: string;
        password: string;
        timezone: string;
    }): Observable<any> {
        return this.post('register', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this.post('api/auth/unlock-session', credentials);
    }

    setLanguage(languageId: number) {
        // update language in local storage
        let user: User = JSON.parse(StorageService.getItem('user'));
        if (user.languageId != languageId) {
            user.languageId = languageId;
            StorageService.setUserState(user);
        }

        switch (languageId) {
            case 1:
                this._translateService.use('en');
                break;
            case 2:
                this._translateService.use('de');
                break;
            default:
                this._translateService.use('en');
        }
    }

    setCalendarOptions(calendarSettings: CalendarSettings) {
        let user: User = JSON.parse(StorageService.getItem('user'));

        user.calendarView = calendarSettings.calendarView;
        user.myCalendar = calendarSettings.myCalendar;
        user.organizedByMe = calendarSettings.organizedByMe;
        StorageService.setUserState(user);
    }

    /**
     * Check the authentication status
     */
    checkAuthenticationStatus(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        // Check the access token availability
        if (!StorageService.getItem('accessToken')) {
            return of(false);
        }

        // Check the access token expire date
        if (AuthUtils.isTokenExpired(StorageService.getItem('accessToken'))) {
            return of(false);
        }

        this._authenticated = true;

        this._userService.user = StorageService.getItem('user');
        let user: User = JSON.parse(StorageService.getItem('user'));

        this.setLanguage(user.languageId);
        // If the access token exists and it didn't expire, sign in using it
        return of(true);
    }

    /**
     * Check the authentication status
     */
    checkAuthorizationStatus(segments: UrlSegment[]): Observable<boolean> {
        // Check which package user is trying to access
        let permissions: Permissions = JSON.parse(
            StorageService.getItem('permissions')
        );
        if (segments[1]) {
            if(segments[1].path !== 'settings'){
                return of(true)
            }
            if (segments[1].path === 'settings' && permissions.isSuperAdmin) {
                return of(true);
            }
            return of(false);
        }

        return of(true);
    }

    deleteUser(companyId: number, userId: string): Observable<any> {
        return this.delete(`${companyId}/${userId}`);
    }

    inviteUser(user: UserInvite): Observable<UserInvite> {
        return this.post('invite-user', user);
    }

    addUserFromAdmin(companyId: number, user: User): Observable<User> {
        return this.post(`register-user/${companyId}`, user);
    }

    getUserInfo(userId: string): Observable<User> {
        return this.get(`get-profile/${userId}`);
    }

    updateUserFromAdmin(
        companyId: number,
        userId: string,
        user: User
    ): Observable<User> {
        return this.put(`edit-users-profile/${companyId}/${userId}`, user);
    }

    updateUserPassword(
        companyId: number,
        userId: string,
        pw: { userId: string; token: string; password: string }
    ): Observable<any> {
        return this.post(`change-users-password/${companyId}/${userId}`, pw);
    }

    confirmInvitation(token: any): Observable<any> {
        return this.post(`confirm-invitation`, token);
    }

    signUpForCompany(companyId: number, user: User): Observable<User> {
        return this.post(`register-invited-user/${companyId}`, user);
    }
}
